import { combineReducers } from 'redux'
import { reducers as I18nReducers } from './I18n'
import { reducers as AccountReducers } from './Account'
import { reducers as DataReducers } from './Data'
import { reducers as StockReducers } from './Stock'
import { reducers as MinigameReducers } from './Minigame'
import { reducers as ZreationReducers } from './Zreation'
import { reducers as DapperReducers } from './Dapper'
import { reducers as StooEventReducers } from './StooEvent'

// Functions to catch actions dispatched
export default combineReducers({
    I18nReducers,
    AccountReducers,
    DataReducers,
    StockReducers,
    MinigameReducers,
    ZreationReducers,
    DapperReducers,
    StooEventReducers,
});