import * as TYPE from './types'

const dispatchEventListAsync = (data) => ({
  type: TYPE.STOO_EVENT_LIST,
  data,
});
const dispatchEventCreateAsync = (data) => ({
  type: TYPE.STOO_EVENT_CREATE,
  data,
});
const dispatchEventActiveAsync = (data) => ({
  type: TYPE.STOO_EVENT_ACTIVE,
  data,
});
const dispatchEventSendMailAsync = (data) => ({
  type: TYPE.STOO_EVENT_SEND_MAIL,
  data,
});
const dispatchEventGetConfirmAsync = (data) => ({
  type: TYPE.STOO_EVENT_GET_CONFIRM,
  data,
});
const dispatchEventUpgradeAsync = (data) => ({
  type: TYPE.STOO_EVENT_UPGRADE,
  data,
});
const dispatchEventForgotPasswordAsync = (data) => ({
  type: TYPE.STOO_EVENT_FORGOT_PASSWORD,
  data,
});
const dispatchEventDeleteAsync = (data) => ({
  type: TYPE.STOO_EVENT_DELETE,
  data,
});
const dispatchRootDownloadCsvCustomersAsync = (data) => ({
  type: TYPE.STOO_EVENT_DOWNLOAD_CUSTOMERS,
  data,
});
const dispatchEventCreateViewOnlyAsync = (data) => ({
  type: TYPE.STOO_EVENT_CREATE_VIEW_ONLY,
  data,
});

export default {
  dispatchEventListAsync,
  dispatchEventCreateAsync,
  dispatchEventActiveAsync,
  dispatchEventSendMailAsync,
  dispatchEventGetConfirmAsync,
  dispatchEventUpgradeAsync,
  dispatchEventForgotPasswordAsync,
  dispatchRootDownloadCsvCustomersAsync,
  dispatchEventDeleteAsync,
  dispatchEventCreateViewOnlyAsync,
};

export const ListAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_LIST_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_CREATE_ASYNC,
    payload
  }
}
export const ActiveAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_ACTIVE_ASYNC,
    payload
  }
}
export const SendMailAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_SEND_MAIL_ASYNC,
    payload
  }
}
export const GetConfirmAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_GET_CONFIRM_ASYNC,
    payload
  }
}
export const UpgradeAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_UPGRADE_ASYNC,
    payload
  }
}
export const ForgotPasswordAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_FORGOT_PASSWORD_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_DELETE_ASYNC,
    payload
  }
}
export const DownloadCustomersAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_DOWNLOAD_CUSTOMERS_ASYNC,
    payload
  }
}
export const CreateViewOnlyAsync = (payload) => {
  return {
    type: TYPE.STOO_EVENT_CREATE_VIEW_ONLY_ASYNC,
    payload
  }
}