// MINIGAME
export const MINIGAME_LIST = "MINIGAME_LIST"
export const MINIGAME_LIST_ASYNC = "MINIGAME_LIST_ASYNC"
export const MINIGAME_CREATE = "MINIGAME_CREATE"
export const MINIGAME_CREATE_ASYNC = "MINIGAME_CREATE_ASYNC"
export const MINIGAME_UPDATE = "MINIGAME_UPDATE"
export const MINIGAME_UPDATE_ASYNC = "MINIGAME_UPDATE_ASYNC"
export const MINIGAME_DELETE = "MINIGAME_DELETE"
export const MINIGAME_DELETE_ASYNC = "MINIGAME_DELETE_ASYNC"
export const MINIGAME_ACTIVE = "MINIGAME_ACTIVE"
export const MINIGAME_ACTIVE_ASYNC = "MINIGAME_ACTIVE_ASYNC"