import { connect } from 'react-redux';

import mapStateToProps from '../redux/mapStateToProps';
import mapDispatchToProps from '../redux/mapDispatchToProps';

export default InputComponent => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(InputComponent);
};