import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
// Minigame
function asyncMinigameList(data) {
  const { data: { secret, locale } } = data;
  return axios.get(Url.END_POINT + "/api/minigame?secret=" + secret,
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMinigameCreate(data) {
  const { data: { username, email, password, secret, locale } } = data;
  return axios.post(Url.END_POINT + "/api/minigame",
    {
      username, email, password,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMinigameDelete(data) {
  const { data: { secret, uuid, locale } } = data;
  return axios.delete(Url.END_POINT + "/api/minigame",
    {
      data: {
        uuid,
        secret,
        locale,
      },
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMinigameUpdate(data) {
  const { data: { secret, uuid, email, password, locale } } = data;
  return axios.put(Url.END_POINT + "/api/minigame",
    {
      uuid,
      email,
      password,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncMinigameActive(data) {
  const { data: { secret, uuid, locale } } = data;
  return axios.put(Url.END_POINT + "/api/minigame/active",
    {
      uuid,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
// Minigame 
function* actionMinigameList(data) {
  const response = yield call(asyncMinigameList, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message } } = response.data;
    result.message = Message;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { User } } = response.data;

      result.data = User;
    }
  }
  // console.log(result) return;
  yield put(actions.MinigameListAsync(result))
}
function* actionMinigameCreate(data) {
  const response = yield call(asyncMinigameCreate, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message }, Status } = response.data;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.MinigameCreateAsync(result))
}
function* actionMinigameDelete(data) {
  const response = yield call(asyncMinigameDelete, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message }, Status } = response.data;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.MinigameDeleteAsync(result))
}
function* actionMinigameUpdate(data) {
  const response = yield call(asyncMinigameUpdate, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message }, Status } = response.data;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.MinigameUpdateAsync(result))
}
function* actionMinigameActive(data) {
  const response = yield call(asyncMinigameActive, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message }, Status } = response.data;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.MinigameActiveAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.MINIGAME_LIST, actionMinigameList)
  yield takeLatest(TYPE.MINIGAME_CREATE, actionMinigameCreate)
  yield takeLatest(TYPE.MINIGAME_DELETE, actionMinigameDelete)
  yield takeLatest(TYPE.MINIGAME_UPDATE, actionMinigameUpdate)
  yield takeLatest(TYPE.MINIGAME_ACTIVE, actionMinigameActive)
}


export default CustomSaga