import * as TYPE from './types'

const dispatchDapperListAsync = (data) => ({
  type: TYPE.DAPPER_LIST,
  data,
});
const dispatchDapperCreateAsync = (data) => ({
  type: TYPE.DAPPER_CREATE,
  data,
});
const dispatchDapperActiveAsync = (data) => ({
  type: TYPE.DAPPER_ACTIVE,
  data,
});
const dispatchDapperSendMailAsync = (data) => ({
  type: TYPE.DAPPER_SEND_MAIL,
  data,
});
const dispatchDapperGetConfirmAsync = (data) => ({
  type: TYPE.DAPPER_GET_CONFIRM,
  data,
});
const dispatchDapperUpgradeAsync = (data) => ({
  type: TYPE.DAPPER_UPGRADE,
  data,
});
const dispatchDapperForgotPasswordAsync = (data) => ({
  type: TYPE.DAPPER_FORGOT_PASSWORD,
  data,
});
const dispatchDapperDeleteAsync = (data) => ({
  type: TYPE.DAPPER_DELETE,
  data,
});
const dispatchDapperCreateViewOnlyAsync = (data) => ({
  type: TYPE.DAPPER_CREATE_VIEW_ONLY,
  data,
});

export default {
  dispatchDapperListAsync,
  dispatchDapperCreateAsync,
  dispatchDapperActiveAsync,
  dispatchDapperSendMailAsync,
  dispatchDapperGetConfirmAsync,
  dispatchDapperUpgradeAsync,
  dispatchDapperForgotPasswordAsync,
  dispatchDapperDeleteAsync,
  dispatchDapperCreateViewOnlyAsync,
};

export const ListAsync = (payload) => {
  return {
    type: TYPE.DAPPER_LIST_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.DAPPER_CREATE_ASYNC,
    payload
  }
}
export const ActiveAsync = (payload) => {
  return {
    type: TYPE.DAPPER_ACTIVE_ASYNC,
    payload
  }
}
export const SendMailAsync = (payload) => {
  return {
    type: TYPE.DAPPER_SEND_MAIL_ASYNC,
    payload
  }
}
export const GetConfirmAsync = (payload) => {
  return {
    type: TYPE.DAPPER_GET_CONFIRM_ASYNC,
    payload
  }
}
export const UpgradeAsync = (payload) => {
  return {
    type: TYPE.DAPPER_UPGRADE_ASYNC,
    payload
  }
}
export const ForgotPasswordAsync = (payload) => {
  return {
    type: TYPE.DAPPER_FORGOT_PASSWORD_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.DAPPER_DELETE_ASYNC,
    payload
  }
}
export const CreateViewOnlyAsync = (payload) => {
  return {
    type: TYPE.DAPPER_CREATE_VIEW_ONLY_ASYNC,
    payload
  }
}