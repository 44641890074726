import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncList(data) {
  const { data: { secret, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event",
    {
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreate(data) {
  const { data: { secret, user_name, full_name, email, password, price, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/create",
    {
      secret,
      user_name,
      full_name,
      email,
      password,
      price,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncActive(data) {
  const { data: { secret, user_name, id, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/active",
    {
      secret,
      user_name,
      id,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncSendMail(data) {
  const { data: { secret, user_name, email, content, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/sendmail",
    {
      secret,
      user_name,
      email,
      content,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetConfirm(data) {
  const { data: { secret, id, price, upgrade_code, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/getconfirm",
    {
      secret,
      id,
      price,
      upgrade_code,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpgrade(data) {
  const { data: { secret, id, user_name, upgrade_code, code, price, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/upgrade",
    {
      secret,
      id,
      user_name,
      upgrade_code,
      code,
      price,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncForgotPassword(data) {
  const { data: { secret, id, user_name, upgrade_code, code, price, password, confirm_password, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/forgot-password",
    {
      id,
      user_name,
      password,
      confirm_password,
      code,
      price,
      upgrade_code,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { secret, id, user_name, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/delete",
    {
      id,
      user_name,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDownloadCustomers(data) {
  const { data: { secret, id, user_name, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/download-customers",
    {
      id,
      user_name,
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreateViewOnly(data) {
  const { data: { secret, user_name, full_name, email, password, user_uuid, locale } } = data;
  return axios.post(Url.END_POINT + "/api/stoo_event/view-only/create",
    {
      secret,
      user_name,
      full_name,
      email,
      password,
      user_uuid,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message } } = response.data;
    result.message = Message;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { User } } = response.data;

      result.data = User;
    }
  }
  // console.log(result) return;
  yield put(actions.ListAsync(result))
}
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User }, Status } = response.data;
    result.message = Message;
    result.data = User;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.CreateAsync(result))
}
function* actionActive(data) {
  const response = yield call(asyncActive, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User } } = response.data;
    result.message = Message;
    result.data = User;
    result.status = response.status;
  }
  // console.log(result) return;
  yield put(actions.ActiveAsync(result))
}
function* actionSendMail(data) {
  const response = yield call(asyncSendMail, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Message } = response.data;
    result.message = Message;
    result.status = response.status;
  }
  // console.log(result) return;
  yield put(actions.SendMailAsync(result))
}
function* actionGetConfirm(data) {
  const response = yield call(asyncGetConfirm, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Message } = response.data;
    result.message = Message;
    result.status = response.status;
  }
  // console.log(result) return;
  yield put(actions.GetConfirmAsync(result))
}
function* actionUpgrade(data) {
  const response = yield call(asyncUpgrade, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User }, Status } = response.data;
    result.data = User;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.UpgradeAsync(result))
}
function* actionForgotPassword(data) {
  const response = yield call(asyncForgotPassword, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User }, Status } = response.data;
    result.data = User;
    result.message = Message;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.ForgotPasswordAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User }, Status } = response.data;
    result.message = Message;
    result.data = User;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.DeleteAsync(result))
}
function* actionDownloadCustomers(data) {
  const response = yield call(asyncDownloadCustomers, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result) return;
  yield put(actions.DownloadCustomersAsync(result))
}
function* actionCreateViewOnly(data) {
  const response = yield call(asyncCreateViewOnly, data);

  // handler
  let result = {}
  result.message = "";
  result.status = 422;

  if (response) {
    let { Notification: { Message, User }, Status } = response.data;
    result.message = Message;
    result.data = User;
    result.status = Status;
  }
  // console.log(result) return;
  yield put(actions.CreateViewOnlyAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.STOO_EVENT_LIST, actionList)
  yield takeLatest(TYPE.STOO_EVENT_CREATE, actionCreate)
  yield takeLatest(TYPE.STOO_EVENT_ACTIVE, actionActive)
  yield takeLatest(TYPE.STOO_EVENT_SEND_MAIL, actionSendMail)
  yield takeLatest(TYPE.STOO_EVENT_GET_CONFIRM, actionGetConfirm)
  yield takeLatest(TYPE.STOO_EVENT_UPGRADE, actionUpgrade)
  yield takeLatest(TYPE.STOO_EVENT_FORGOT_PASSWORD, actionForgotPassword)
  yield takeLatest(TYPE.STOO_EVENT_DELETE, actionDelete)
  yield takeLatest(TYPE.STOO_EVENT_DOWNLOAD_CUSTOMERS, actionDownloadCustomers)
  yield takeLatest(TYPE.STOO_EVENT_CREATE_VIEW_ONLY, actionCreateViewOnly)
}


export default CustomSaga