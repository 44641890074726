export const DATA_LIST = "DATA_LIST"
export const DATA_LIST_ASYNC = "DATA_LIST_ASYNC"
export const DATA_CREATE = "DATA_CREATE"
export const DATA_CREATE_ASYNC = "DATA_CREATE_ASYNC"
export const DATA_ACTIVE = "DATA_ACTIVE"
export const DATA_ACTIVE_ASYNC = "DATA_ACTIVE_ASYNC"
export const DATA_SEND_MAIL = "DATA_SEND_MAIL"
export const DATA_SEND_MAIL_ASYNC = "DATA_SEND_MAIL_ASYNC"
export const DATA_GET_CONFIRM = "DATA_GET_CONFIRM"
export const DATA_GET_CONFIRM_ASYNC = "DATA_GET_CONFIRM_ASYNC"
export const DATA_UPGRADE = "DATA_UPGRADE"
export const DATA_UPGRADE_ASYNC = "DATA_UPGRADE_ASYNC"
export const DATA_FORGOT_PASSWORD = "DATA_FORGOT_PASSWORD"
export const DATA_FORGOT_PASSWORD_ASYNC = "DATA_FORGOT_PASSWORD_ASYNC"
export const DATA_DOWNLOAD_CUSTOMERS = "DATA_DOWNLOAD_CUSTOMERS"
export const DATA_DOWNLOAD_CUSTOMERS_ASYNC = "DATA_DOWNLOAD_CUSTOMERS_ASYNC"
export const DATA_DELETE = "DATA_DELETE"
export const DATA_DELETE_ASYNC = "DATA_DELETE_ASYNC"
export const DATA_CREATE_VIEW_ONLY = "DATA_CREATE_VIEW_ONLY"
export const DATA_CREATE_VIEW_ONLY_ASYNC = "DATA_CREATE_VIEW_ONLY_ASYNC"
