import * as TYPE from './types'

const dispatchUpdateLanguage = language => ({
  type: TYPE.UPDATE_LANGUAGE,
  language,
})
const dispatchUpdateMessengerBox = messenger_box => ({
  type: TYPE.UPDATE_MESSENGER_BOX,
  messenger_box,
})

export default {
  dispatchUpdateLanguage,
  dispatchUpdateMessengerBox,
};