import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncLogin(data) {
  const { data: { secret, locale } } = data;
  return axios.post(Url.END_POINT + "/api/auth",
    {
      secret,
      locale,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionLogin(data) {
  const response = yield call(asyncLogin, data);

  // handler
  let result = {}
  result.message = "Tài khoản hoặc mật khẩu không chính xác";
  result.status = 422;

  if (response) {
    let { Message } = response.data;
    result.message = Message;
    result.status = response.status;

    if (response.status === 200) {
      let { Token } = response.data;

      result.token = Token;
      localStorage.setItem("secret", Token);

      // router
      const { history } = data;
      history.push('/');
    }
  }
  // console.log(result) return;
  yield put(actions.LoginAsync(result))
}
function* actionLogout(data) {

  localStorage.removeItem("secret");

  // router
  document.location = '/';
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.ACCOUNT_LOGIN, actionLogin)
  yield takeLatest(TYPE.ACCOUNT_LOGOUT, actionLogout)
}


export default CustomSaga