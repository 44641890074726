import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 296 154" id="prefix__B1L2_gmCf" {...props}>
      <style>
        {
          "@-webkit-keyframes BJGxUhulmCz_SJZ259LAG_Animation{0%,75%,to{-webkit-transform:scale(1,1);transform:scale(1,1)}38.33%{-webkit-transform:scale(1.14,1.12);transform:scale(1.14,1.12)}}@keyframes BJGxUhulmCz_SJZ259LAG_Animation{0%,75%,to{-webkit-transform:scale(1,1);transform:scale(1,1)}38.33%{-webkit-transform:scale(1.14,1.12);transform:scale(1.14,1.12)}}@-webkit-keyframes BJbeIndgQAG_ryJhncICz_Animation{0%,75%,to{-webkit-transform:scale(1.05,1.05);transform:scale(1.05,1.05)}38.33%{-webkit-transform:scale(1,1);transform:scale(1,1)}}@keyframes BJbeIndgQAG_ryJhncICz_Animation{0%,75%,to{-webkit-transform:scale(1.05,1.05);transform:scale(1.05,1.05)}38.33%{-webkit-transform:scale(1,1);transform:scale(1,1)}}@-webkit-keyframes ryoU2uem0z_SJ5TWo8RG_Animation{0%,to{-webkit-transform:translate(0,0);transform:translate(0,0)}50%{-webkit-transform:translate(0,3px);transform:translate(0,3px)}}@keyframes ryoU2uem0z_SJ5TWo8RG_Animation{0%,to{-webkit-transform:translate(0,0);transform:translate(0,0)}50%{-webkit-transform:translate(0,3px);transform:translate(0,3px)}}#prefix__B1L2_gmCf *{-webkit-animation-duration:2s;animation-duration:2s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:cubic-bezier(0,0,1,1);animation-timing-function:cubic-bezier(0,0,1,1)}#prefix__B1LU2Og7AM_rJSaevbym{-webkit-transform:scale(1,1);transform:scale(1,1)}#prefix__B1LU2Og7AM_B1TyzvW1Q,#prefix__B1LU2Og7AM_rJSaevbym,#prefix__B1LU2Og7AM_rJY0lPZJ7{-webkit-transform-origin:50% 50%;transform-origin:50% 50%;transform-box:fill-box}#prefix__B1LU2Og7AM_rJY0lPZJ7{-webkit-transform:translate(0,0);transform:translate(0,0)}#prefix__B1LU2Og7AM_B1TyzvW1Q{-webkit-transform:rotate(0deg);transform:rotate(0deg)}"
        }
      </style>
      <title>{"Group"}</title>
      <defs>
        <path
          d="M19 165.946c-10.622-.768-19-9.629-19-20.446C0 134.178 9.178 125 20.5 125c.232 0 .463.004.692.011C22.657 113.721 32.31 105 44 105c9.913 0 18.361 6.271 21.594 15.063.463-.042.932-.063 1.406-.063 8.27 0 14.977 6.47 15 14.46 5.897 2.589 10 8.35 10 15.04 0 8.787-7.077 15.97-16 16.472V166H19v-.054z"
          id="prefix__path-1"
        />
        <path
          d="M34.606 142.932C15.298 141.764 0 125.712 0 106.082c0-20.389 16.504-36.918 36.862-36.918.362 0 .723.006 1.083.016C40.525 48.777 57.918 33 78.99 33c17.85 0 33.059 11.32 38.862 27.181.582-.038 1.17-.058 1.762-.058 14.115 0 25.637 11.125 26.3 25.098C156.559 89.864 164 100.493 164 112.863c0 16.139-12.667 29.315-28.587 30.1V143H34.606v-.068z"
          id="prefix__path-2"
        />
        <path
          d="M53.2 13.177l8.458 2.27a20 20 0 0112.595 10.158l9.818 19.062a9 9 0 01-8 13.121H17.383a5 5 0 01-4.114-7.841l20.452-29.614A18 18 0 0153.2 13.177z"
          id="prefix__path-3"
        />
        <path
          d="M23 72.955C10.168 72.18 0 61.527 0 48.5 0 34.969 10.969 24 24.5 24c.24 0 .48.003.72.01C26.933 10.47 38.493 0 52.5 0c11.863 0 21.972 7.512 25.83 18.039.386-.026.777-.039 1.17-.039 9.382 0 17.04 7.383 17.48 16.655C104.054 37.737 109 44.791 109 53c0 10.71-8.419 19.454-19 19.975V73H23v-.045z"
          id="prefix__path-5"
        />
        <rect
          id="prefix__path-4"
          x={1.017}
          y={44.55}
          width={83.561}
          height={18}
          rx={6}
        />
      </defs>
      <g
        style={{
          WebkitTransform: "translate(-534px,-230px)",
        }}
        fillRule="evenodd"
        fill="none"
      >
        <g
          style={{
            WebkitTransform: "translate(534px,218px)",
          }}
          transform="translate(0 -12)"
        >
          <g id="prefix__H1XUhdgmCM">
            <use xlinkHref="#prefix__path-1" fill="#ebf9ff" />
            <path
              d="M19.801 164.5H75.46l.457-.026c8.176-.46 14.584-7.029 14.584-14.974 0-5.94-3.601-11.251-9.103-13.667l-.894-.393-.003-.976C80.48 127.31 74.453 121.5 67 121.5c-.427 0-.851.019-1.272.057l-1.145.103-.397-1.08A21.507 21.507 0 0044 106.5c-10.834 0-19.939 8.06-21.32 18.704l-.175 1.352-1.362-.045c-.214-.007-.428-.011-.643-.011-10.493 0-19 8.507-19 19 0 9.976 7.716 18.234 17.608 18.95l.693.05z"
              stroke="#34c3ff"
              strokeWidth={3}
            />
          </g>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              WebkitTransform:
                "translate(223.165064px,67.982051px) scale(-1,1) rotate(60deg) translate(-223.165064px,-67.982051px)",
            }}
            stroke="#34c3ff"
            fill="#34c3ff"
            transform="scale(-1 1) rotate(60 0 -318.551)"
            d="M222.665 65.482l-.5 2.5 3-1-1.5 3.5.5-2.5-3 1z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              WebkitTransform:
                "translate(14.200686px,113.139058px) scale(-1,1) rotate(50deg) translate(-14.200686px,-113.139058px)",
            }}
            stroke="#34c3ff"
            fill="#34c3ff"
            transform="scale(-1 1) rotate(50 0 82.686)"
            d="M13.7 110.64l-.5 2.5 3-1-1.5 3.5.5-2.5-3 1z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              WebkitTransform:
                "translate(3.200686px,107.139058px) scale(-1,1) rotate(50deg) translate(-3.200686px,-107.139058px)",
            }}
            stroke="#34c3ff"
            fill="#34c3ff"
            transform="scale(-1 1) rotate(50 0 100.275)"
            d="M2.7 104.64l-.5 2.5 3-1-1.5 3.5.5-2.5-3 1z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              WebkitTransform:
                "translate(236.278116px,62.401373px) scale(-1,1) rotate(40deg) translate(-236.278116px,-62.401373px)",
            }}
            stroke="#34c3ff"
            fill="#34c3ff"
            transform="scale(-1 1) rotate(40 0 -586.767)"
            d="M235.278 57.401l-1 5 6-2-3 7 1-5-6 2z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              WebkitTransform:
                "translate(259.427947px,52.211208px) scale(-1,1) rotate(80deg) translate(-259.427947px,-52.211208px)",
            }}
            stroke="#34c3ff"
            fill="#34c3ff"
            transform="scale(-1 1) rotate(80 0 -256.963)"
            d="M257.928 44.711l-1.5 7.5 9-3-4.5 10.5 1.5-7.5-9 3z"
          />
          <g
            style={{
              WebkitAnimationName: "ryoU2uem0z_SJ5TWo8RG_Animation",
              animationName: "ryoU2uem0z_SJ5TWo8RG_Animation",
              WebkitTransformOrigin: "50% 50%",
              transformOrigin: "50% 50%",
              transformBox: "fill-box",
              WebkitTransform: "translate(0,0)",
            }}
          >
            <g
              style={{
                WebkitTransform: "translate(58px,0)",
              }}
              data-animator-group="true"
              data-animator-type={0}
            >
              <g
                style={{
                  WebkitTransform:
                    "translate(82px,88px) scale(-1,1) translate(-82px,-88px)",
                }}
                transform="matrix(-1 0 0 1 222 0)"
              >
                <use xlinkHref="#prefix__path-2" fill="#fff" />
                <path
                  d="M35.776 141.5h98.85l.713-.035c15.178-.749 27.161-13.32 27.161-28.602a28.643 28.643 0 00-17.185-26.268l-.854-.372-.044-.931c-.628-13.222-11.536-23.669-24.802-23.669-.557 0-1.111.019-1.663.055l-1.122.074-.386-1.055C110.734 45.09 95.861 34.5 78.991 34.5c-20.139 0-37.05 15.036-39.558 34.868l-.171 1.35-1.36-.039c-.346-.01-.693-.015-1.04-.015-19.53 0-35.362 15.857-35.362 35.418 0 18.754 14.597 34.228 33.196 35.353l1.08.065z"
                  stroke="#34c3ff"
                  strokeWidth={3}
                />
              </g>
              <path
                d="M100 94c3.05 2.667 6.072 4 9.067 4 2.996 0 5.973-1.333 8.933-4"
                strokeLinecap="round"
                style={{
                  WebkitTransform:
                    "translate(51px,96px) scale(1,-1) translate(-51px,-96px)",
                }}
                stroke="#34c3ff"
                strokeWidth={2}
              />
              <path
                d="M159 94c3.05 2.667 6.072 4 9.067 4 2.996 0 5.973-1.333 8.933-4"
                strokeLinecap="round"
                style={{
                  WebkitTransform:
                    "translate(110px,96px) scale(1,-1) translate(-110px,-96px)",
                }}
                stroke="#34c3ff"
                strokeWidth={2}
              />
              <path
                d="M141.845 128.555c.677 1.044 1.21 2.182 1.076 3.032-.164 1.037-1.257 1.927-2.403 1.746-1.145-.182-1.93-1.237-1.703-2.669.061-.386.09-.575.112-.707-1.387.368-3.034.541-4.802.541-4.93 0-8.925-1.348-8.925-4.498 0-2.1 2.1-4.2 4.725-3.1 1.575 1 6.825 1 8.4 0 2.625-1.1 4.725 1.001 4.725 3.1 0 1.044-.439 1.89-1.205 2.555z"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#34c3ff"
                strokeWidth={2.1}
                data-animator-group="true"
                data-animator-type={2}
              />
              <g
                style={{
                  WebkitAnimationName: "BJGxUhulmCz_SJZ259LAG_Animation",
                  animationName: "BJGxUhulmCz_SJZ259LAG_Animation",
                  WebkitAnimationTimingFunction: "cubic-bezier(.42,0,1,1)",
                  animationTimingFunction: "cubic-bezier(.42,0,1,1)",
                  WebkitTransformOrigin: "100% 50%",
                  transformOrigin: "100% 50%",
                  transformBox: "fill-box",
                  WebkitTransform: "scale(1,1)",
                }}
              >
                <path
                  d="M44.821 115.55c8.844-2.034 18.772-8.135 22.054-10.5-14.443 3.035-16.979-9.358-26.407-9.688-7.043-.246-14.124 3.536-13.222 11.098.902 7.563 8.732 11.125 17.575 9.09z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    WebkitTransform:
                      "translate(47.021427px,105.732908px) rotate(17deg) translate(-47.021427px,-105.732908px)",
                  }}
                  stroke="#34c3ff"
                  fill="#f7f7fa"
                  transform="rotate(17 76.021 299.776)"
                  data-animator-group="true"
                  data-animator-type={2}
                />
              </g>
              <g
                style={{
                  WebkitTransform: "translate(51px,0)",
                }}
                transform="translate(109)"
              >
                <g
                  style={{
                    WebkitTransform:
                      "translate(50.540083px,34.505539px) rotate(20deg) translate(-50.540083px,-34.505539px)",
                  }}
                  transform="rotate(20 50.54 34.506)"
                >
                  <use xlinkHref="#prefix__path-3" fill="#c2edff" />
                  <path
                    d="M52.94 14.142a17 17 0 00-18.395 6.759L14.093 50.515a4 4 0 003.291 6.273H76.07a8 8 0 007.112-11.663l-9.818-19.062a19 19 0 00-11.966-9.65l-8.458-2.27z"
                    stroke="#34c3ff"
                    strokeWidth={2}
                  />
                </g>
                <g
                  style={{
                    WebkitTransform:
                      "translate(42.797687px,53.549981px) rotate(20deg) translate(-42.797687px,-53.549981px)",
                  }}
                  transform="rotate(20 42.798 53.55)"
                >
                  <use xlinkHref="#prefix__path-4" fill="#fff" />
                  <rect
                    x={2.017}
                    y={45.55}
                    width={81.561}
                    height={16}
                    rx={6}
                    stroke="#34c3ff"
                    strokeWidth={2}
                  />
                </g>
                <path
                  d="M76 35l5 36c-6.15 2.004-9.817 2.338-11 1-1.183-1.338-7.85-11.004-20-29"
                  fill="#c2edff"
                />
                <path
                  d="M80.38 69.098l-1.361 2.177-.233-1.678c.51-.152 1.04-.318 1.594-.499zm-6.316-33.502L76 35l5 36c-6.15 2.004-9.817 2.338-11 1-1.183-1.338-7.85-11.004-20-29l1.998-.615c11.794 17.464 18.42 27.07 19.5 28.29-.019-.022.047.012.26.056.392.081.963.097 1.698.03 1.348-.122 3.13-.51 5.33-1.164l-4.722-34.001zm6.316 33.502l-1.361 2.177-.233-1.678c.51-.152 1.04-.318 1.594-.499zm-6.316-33.502L76 35l5 36c-6.15 2.004-9.817 2.338-11 1-1.183-1.338-7.85-11.004-20-29l1.998-.615c11.794 17.464 18.42 27.07 19.5 28.29-.019-.022.047.012.26.056.392.081.963.097 1.698.03 1.348-.122 3.13-.51 5.33-1.164l-4.722-34.001z"
                  fillRule="nonzero"
                  fill="#34c3ff"
                />
                <circle
                  cx={75.5}
                  cy={74.5}
                  r={10.5}
                  stroke="#34c3ff"
                  strokeWidth={2}
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g
            style={{
              WebkitTransform: "translate(187px,86px)",
            }}
            transform="translate(187 86)"
          >
            <g id="prefix__S1RgUhulXAf">
              <use xlinkHref="#prefix__path-5" fill="#ebf9ff" />
              <path
                d="M23.793 71.5h65.671l.462-.023C99.746 70.994 107.5 62.873 107.5 53a18.503 18.503 0 00-11.12-16.97l-.854-.372-.044-.931C95.078 26.22 88.049 19.5 79.5 19.5c-.359 0-.716.012-1.072.035l-1.12.074-.387-1.054C73.198 8.395 63.5 1.5 52.5 1.5c-13.13 0-24.158 9.789-25.793 22.699l-.17 1.35-1.36-.04c-.226-.006-.451-.009-.677-.009-12.703 0-23 10.297-23 23 0 12.178 9.494 22.227 21.59 22.958l.703.042z"
                stroke="#34c3ff"
                strokeWidth={3}
              />
            </g>
            <path
              d="M56 57c3.05.667 6.072 1 9.067 1 2.996 0 5.973-.333 8.933-1"
              strokeLinecap="round"
              style={{
                WebkitTransform:
                  "translate(65px,57.5px) scale(1,-1) translate(-65px,-57.5px)",
              }}
              stroke="#34c3ff"
              strokeWidth={2}
            />
            <path
              d="M43 40c1.694 1.333 3.373 2 5.037 2 1.664 0 3.319-.667 4.963-2"
              strokeLinecap="round"
              style={{
                WebkitTransform:
                  "translate(48px,41px) scale(1,-1) translate(-48px,-41px)",
              }}
              stroke="#34c3ff"
              strokeWidth={2}
            />
            <path
              d="M74 40c1.694 1.333 3.373 2 5.037 2 1.664 0 3.319-.667 4.963-2"
              strokeLinecap="round"
              style={{
                WebkitTransform:
                  "translate(79px,41px) scale(1,-1) translate(-79px,-41px)",
              }}
              stroke="#34c3ff"
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
      <script>
        {
          "(function(){var a=document.querySelector(&apos;#B1L2_gmCf&apos;),b=a.querySelectorAll(&apos;style&apos;),c=function(d){b.forEach(function(f){var g=f.textContent;g&amp;&amp;(f.textContent=g.replace(/transform-box:[^;\\r\\n]*/gi,&apos;transform-box: &apos;+d))})};c(&apos;initial&apos;),window.requestAnimationFrame(function(){return c(&apos;fill-box&apos;)})})();"
        }
      </script>
    </svg>
  )
}

export default SvgComponent
