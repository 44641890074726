export const ZREATION_LIST = "ZREATION_LIST"
export const ZREATION_LIST_ASYNC = "ZREATION_LIST_ASYNC"
export const ZREATION_CREATE = "ZREATION_CREATE"
export const ZREATION_CREATE_ASYNC = "ZREATION_CREATE_ASYNC"
export const ZREATION_ACTIVE = "ZREATION_ACTIVE"
export const ZREATION_ACTIVE_ASYNC = "ZREATION_ACTIVE_ASYNC"
export const ZREATION_SEND_MAIL = "ZREATION_SEND_MAIL"
export const ZREATION_SEND_MAIL_ASYNC = "ZREATION_SEND_MAIL_ASYNC"
export const ZREATION_GET_CONFIRM = "ZREATION_GET_CONFIRM"
export const ZREATION_GET_CONFIRM_ASYNC = "ZREATION_GET_CONFIRM_ASYNC"
export const ZREATION_UPGRADE = "ZREATION_UPGRADE"
export const ZREATION_UPGRADE_ASYNC = "ZREATION_UPGRADE_ASYNC"
export const ZREATION_FORGOT_PASSWORD = "ZREATION_FORGOT_PASSWORD"
export const ZREATION_FORGOT_PASSWORD_ASYNC = "ZREATION_FORGOT_PASSWORD_ASYNC"
export const ZREATION_DELETE = "ZREATION_DELETE"
export const ZREATION_DELETE_ASYNC = "ZREATION_DELETE_ASYNC"
export const ZREATION_CREATE_VIEW_ONLY = "ZREATION_CREATE_VIEW_ONLY"
export const ZREATION_CREATE_VIEW_ONLY_ASYNC = "ZREATION_CREATE_VIEW_ONLY_ASYNC"
