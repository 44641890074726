import * as TYPE from './types'

// Minigame
const dispatchMinigameListAsync = (data) => ({
  type: TYPE.MINIGAME_LIST,
  data,
});
const dispatchMinigameCreateAsync = (data) => ({
  type: TYPE.MINIGAME_CREATE,
  data,
});
const dispatchMinigameDeleteAsync = (data) => ({
  type: TYPE.MINIGAME_DELETE,
  data,
});
const dispatchMinigameUpdateAsync = (data) => ({
  type: TYPE.MINIGAME_UPDATE,
  data,
});
const dispatchMinigameActiveAsync = (data) => ({
  type: TYPE.MINIGAME_ACTIVE,
  data,
});

export default {
  // minigame
  dispatchMinigameListAsync,
  dispatchMinigameCreateAsync,
  dispatchMinigameDeleteAsync,
  dispatchMinigameUpdateAsync,
  dispatchMinigameActiveAsync,
};

// Minigame 
export const MinigameListAsync = (payload) => {
  return {
    type: TYPE.MINIGAME_LIST_ASYNC,
    payload
  }
}
export const MinigameCreateAsync = (payload) => {
  return {
    type: TYPE.MINIGAME_CREATE_ASYNC,
    payload
  }
}
export const MinigameDeleteAsync = (payload) => {
  return {
    type: TYPE.MINIGAME_DELETE_ASYNC,
    payload
  }
}
export const MinigameUpdateAsync = (payload) => {
  return {
    type: TYPE.MINIGAME_UPDATE_ASYNC,
    payload
  }
}
export const MinigameActiveAsync = (payload) => {
  return {
    type: TYPE.MINIGAME_ACTIVE_ASYNC,
    payload
  }
}