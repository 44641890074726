import React from 'react';
import ErrorPage from './ErrorPage'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        console.log(error, info);
        // debugger;
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // return <h1>Something went wrong.</h1>;
            return <ErrorPage code={"500"} /> 
        }
        return this.props.children;
    }
}
export default ErrorBoundary;