import * as React from 'react';
import Error404 from '../../assets/svg/Error404'
import Error500 from '../../assets/svg/Error500'

const ErrorPage = ({ code = 404 }) => (
  <div className="error-page">
    <div className="item">
      {code == 404 ? <Error404 /> : <Error500 />}
      <div className="text">
        <h1 className="code">{code}</h1>
      </div>
    </div>
  </div>
);

export default ErrorPage;