import * as TYPE from './types'

const initialState = {
  token: localStorage.getItem('secret'),
  messageLogin: "",
  statusLogin: 200,
}

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.ACCOUNT_UNMOUNT: 
      return {
        ...state,
        token: localStorage.getItem('secret'),
        messageLogin: "",
        statusLogin: 200,
      };
    case TYPE.ACCOUNT_LOGIN_ASYNC:
      return {
        ...state,
        token: action.payload.token,
        messageLogin: action.payload.message,
        statusLogin: action.payload.status,
      }
    default:
      return state
  }
}

export default AccountReducer