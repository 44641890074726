import * as TYPE from './types'

const dispatchZreationListAsync = (data) => ({
  type: TYPE.ZREATION_LIST,
  data,
});
const dispatchZreationCreateAsync = (data) => ({
  type: TYPE.ZREATION_CREATE,
  data,
});
const dispatchZreationActiveAsync = (data) => ({
  type: TYPE.ZREATION_ACTIVE,
  data,
});
const dispatchZreationSendMailAsync = (data) => ({
  type: TYPE.ZREATION_SEND_MAIL,
  data,
});
const dispatchZreationGetConfirmAsync = (data) => ({
  type: TYPE.ZREATION_GET_CONFIRM,
  data,
});
const dispatchZreationUpgradeAsync = (data) => ({
  type: TYPE.ZREATION_UPGRADE,
  data,
});
const dispatchZreationForgotPasswordAsync = (data) => ({
  type: TYPE.ZREATION_FORGOT_PASSWORD,
  data,
});
const dispatchZreationDeleteAsync = (data) => ({
  type: TYPE.ZREATION_DELETE,
  data,
});
const dispatchZreationCreateViewOnlyAsync = (data) => ({
  type: TYPE.ZREATION_CREATE_VIEW_ONLY,
  data,
});

export default {
  dispatchZreationListAsync,
  dispatchZreationCreateAsync,
  dispatchZreationActiveAsync,
  dispatchZreationSendMailAsync,
  dispatchZreationGetConfirmAsync,
  dispatchZreationUpgradeAsync,
  dispatchZreationForgotPasswordAsync,
  dispatchZreationDeleteAsync,
  dispatchZreationCreateViewOnlyAsync,
};

export const ListAsync = (payload) => {
  return {
    type: TYPE.ZREATION_LIST_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.ZREATION_CREATE_ASYNC,
    payload
  }
}
export const ActiveAsync = (payload) => {
  return {
    type: TYPE.ZREATION_ACTIVE_ASYNC,
    payload
  }
}
export const SendMailAsync = (payload) => {
  return {
    type: TYPE.ZREATION_SEND_MAIL_ASYNC,
    payload
  }
}
export const GetConfirmAsync = (payload) => {
  return {
    type: TYPE.ZREATION_GET_CONFIRM_ASYNC,
    payload
  }
}
export const UpgradeAsync = (payload) => {
  return {
    type: TYPE.ZREATION_UPGRADE_ASYNC,
    payload
  }
}
export const ForgotPasswordAsync = (payload) => {
  return {
    type: TYPE.ZREATION_FORGOT_PASSWORD_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.ZREATION_DELETE_ASYNC,
    payload
  }
}
export const CreateViewOnlyAsync = (payload) => {
  return {
    type: TYPE.ZREATION_CREATE_VIEW_ONLY_ASYNC,
    payload
  }
}