import * as TYPE from './types'

const initialState = {
  data: [],
  data_updated: null,
  data_customers_link_csv: null,
  message: "",
  status: 500,
}

function DataReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DATA_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
      }
    case TYPE.DATA_CREATE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_ACTIVE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_SEND_MAIL_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_GET_CONFIRM_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_UPGRADE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_FORGOT_PASSWORD_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_DELETE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_DOWNLOAD_CUSTOMERS_ASYNC:
      return {
        ...state,
        data_customers_link_csv: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.DATA_CREATE_VIEW_ONLY_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    default:
      return state
  }
}

export default DataReducer