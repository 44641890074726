import * as TYPE from './types'

const dispatchLoginAsync = (data, history) => ({
  type: TYPE.ACCOUNT_LOGIN,
  data,
  history,
});
const dispatchLogoutAsync = (history) => ({
  type: TYPE.ACCOUNT_LOGOUT,
  history,
});

export default {
  dispatchLoginAsync,
  dispatchLogoutAsync,

};

export const LoginAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LOGIN_ASYNC,
    payload
  }
}