import * as TYPE from './types'

const dispatchDataListAsync = (data) => ({
  type: TYPE.DATA_LIST,
  data,
});
const dispatchDataCreateAsync = (data) => ({
  type: TYPE.DATA_CREATE,
  data,
});
const dispatchDataActiveAsync = (data) => ({
  type: TYPE.DATA_ACTIVE,
  data,
});
const dispatchDataSendMailAsync = (data) => ({
  type: TYPE.DATA_SEND_MAIL,
  data,
});
const dispatchDataGetConfirmAsync = (data) => ({
  type: TYPE.DATA_GET_CONFIRM,
  data,
});
const dispatchDataUpgradeAsync = (data) => ({
  type: TYPE.DATA_UPGRADE,
  data,
});
const dispatchDataForgotPasswordAsync = (data) => ({
  type: TYPE.DATA_FORGOT_PASSWORD,
  data,
});
const dispatchDataDeleteAsync = (data) => ({
  type: TYPE.DATA_DELETE,
  data,
});
const dispatchRootDownloadCsvCustomersAsync = (data) => ({
  type: TYPE.DATA_DOWNLOAD_CUSTOMERS,
  data,
});
const dispatchDataCreateViewOnlyAsync = (data) => ({
  type: TYPE.DATA_CREATE_VIEW_ONLY,
  data,
});

export default {
  dispatchDataListAsync,
  dispatchDataCreateAsync,
  dispatchDataActiveAsync,
  dispatchDataSendMailAsync,
  dispatchDataGetConfirmAsync,
  dispatchDataUpgradeAsync,
  dispatchDataForgotPasswordAsync,
  dispatchRootDownloadCsvCustomersAsync,
  dispatchDataDeleteAsync,
  dispatchDataCreateViewOnlyAsync,
};

export const ListAsync = (payload) => {
  return {
    type: TYPE.DATA_LIST_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.DATA_CREATE_ASYNC,
    payload
  }
}
export const ActiveAsync = (payload) => {
  return {
    type: TYPE.DATA_ACTIVE_ASYNC,
    payload
  }
}
export const SendMailAsync = (payload) => {
  return {
    type: TYPE.DATA_SEND_MAIL_ASYNC,
    payload
  }
}
export const GetConfirmAsync = (payload) => {
  return {
    type: TYPE.DATA_GET_CONFIRM_ASYNC,
    payload
  }
}
export const UpgradeAsync = (payload) => {
  return {
    type: TYPE.DATA_UPGRADE_ASYNC,
    payload
  }
}
export const ForgotPasswordAsync = (payload) => {
  return {
    type: TYPE.DATA_FORGOT_PASSWORD_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.DATA_DELETE_ASYNC,
    payload
  }
}
export const DownloadCustomersAsync = (payload) => {
  return {
    type: TYPE.DATA_DOWNLOAD_CUSTOMERS_ASYNC,
    payload
  }
}
export const CreateViewOnlyAsync = (payload) => {
  return {
    type: TYPE.DATA_CREATE_VIEW_ONLY_ASYNC,
    payload
  }
}