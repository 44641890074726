export const STOO_EVENT_LIST = "STOO_EVENT_LIST"
export const STOO_EVENT_LIST_ASYNC = "STOO_EVENT_LIST_ASYNC"
export const STOO_EVENT_CREATE = "STOO_EVENT_CREATE"
export const STOO_EVENT_CREATE_ASYNC = "STOO_EVENT_CREATE_ASYNC"
export const STOO_EVENT_ACTIVE = "STOO_EVENT_ACTIVE"
export const STOO_EVENT_ACTIVE_ASYNC = "STOO_EVENT_ACTIVE_ASYNC"
export const STOO_EVENT_SEND_MAIL = "STOO_EVENT_SEND_MAIL"
export const STOO_EVENT_SEND_MAIL_ASYNC = "STOO_EVENT_SEND_MAIL_ASYNC"
export const STOO_EVENT_GET_CONFIRM = "STOO_EVENT_GET_CONFIRM"
export const STOO_EVENT_GET_CONFIRM_ASYNC = "STOO_EVENT_GET_CONFIRM_ASYNC"
export const STOO_EVENT_UPGRADE = "STOO_EVENT_UPGRADE"
export const STOO_EVENT_UPGRADE_ASYNC = "STOO_EVENT_UPGRADE_ASYNC"
export const STOO_EVENT_FORGOT_PASSWORD = "STOO_EVENT_FORGOT_PASSWORD"
export const STOO_EVENT_FORGOT_PASSWORD_ASYNC = "STOO_EVENT_FORGOT_PASSWORD_ASYNC"
export const STOO_EVENT_DOWNLOAD_CUSTOMERS = "STOO_EVENT_DOWNLOAD_CUSTOMERS"
export const STOO_EVENT_DOWNLOAD_CUSTOMERS_ASYNC = "STOO_EVENT_DOWNLOAD_CUSTOMERS_ASYNC"
export const STOO_EVENT_DELETE = "STOO_EVENT_DELETE"
export const STOO_EVENT_DELETE_ASYNC = "STOO_EVENT_DELETE_ASYNC"
export const STOO_EVENT_CREATE_VIEW_ONLY = "STOO_EVENT_CREATE_VIEW_ONLY"
export const STOO_EVENT_CREATE_VIEW_ONLY_ASYNC = "STOO_EVENT_CREATE_VIEW_ONLY_ASYNC"
