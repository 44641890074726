import * as TYPE from './types'

const initialState = {
  data: [],
  data_updated: null,
  message: "",
  status: 500,
}

function StockReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.STOCK_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
      }
    case TYPE.STOCK_CREATE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_ACTIVE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_SEND_MAIL_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_GET_CONFIRM_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_UPGRADE_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_FORGOT_PASSWORD_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.STOCK_CREATE_VIEW_ONLY_ASYNC:
      return {
        ...state,
        data_updated: action.payload.data,
        message: action.payload.message,
        status: action.payload.status
      }
    default:
      return state
  }
}

export default StockReducer