import React from 'react';
import ErrorPage from '../../components/common/ErrorPage'

class NotFound extends React.Component {
  render() {
    return (
      <ErrorPage code={"404"} /> 
    )
  }
}

export default NotFound