export const DAPPER_LIST = "DAPPER_LIST"
export const DAPPER_LIST_ASYNC = "DAPPER_LIST_ASYNC"
export const DAPPER_CREATE = "DAPPER_CREATE"
export const DAPPER_CREATE_ASYNC = "DAPPER_CREATE_ASYNC"
export const DAPPER_ACTIVE = "DAPPER_ACTIVE"
export const DAPPER_ACTIVE_ASYNC = "DAPPER_ACTIVE_ASYNC"
export const DAPPER_SEND_MAIL = "DAPPER_SEND_MAIL"
export const DAPPER_SEND_MAIL_ASYNC = "DAPPER_SEND_MAIL_ASYNC"
export const DAPPER_GET_CONFIRM = "DAPPER_GET_CONFIRM"
export const DAPPER_GET_CONFIRM_ASYNC = "DAPPER_GET_CONFIRM_ASYNC"
export const DAPPER_UPGRADE = "DAPPER_UPGRADE"
export const DAPPER_UPGRADE_ASYNC = "DAPPER_UPGRADE_ASYNC"
export const DAPPER_FORGOT_PASSWORD = "DAPPER_FORGOT_PASSWORD"
export const DAPPER_FORGOT_PASSWORD_ASYNC = "DAPPER_FORGOT_PASSWORD_ASYNC"
export const DAPPER_DELETE = "DAPPER_DELETE"
export const DAPPER_DELETE_ASYNC = "DAPPER_DELETE_ASYNC"
export const DAPPER_CREATE_VIEW_ONLY = "DAPPER_CREATE_VIEW_ONLY"
export const DAPPER_CREATE_VIEW_ONLY_ASYNC = "DAPPER_CREATE_VIEW_ONLY_ASYNC"
