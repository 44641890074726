import { all } from 'redux-saga/effects'

import { default as AccountSaga } from './Account/sagas';
import { default as DataSaga } from './Data/sagas';
import { default as StockSaga } from './Stock/sagas';
import { default as MinigameSaga } from './Minigame/sagas';
import { default as ZreationSaga } from './Zreation/sagas';
import { default as DapperSaga } from './Dapper/sagas';
import { default as StooEventSaga } from './StooEvent/sagas';

export default function* rootSaga() {
    yield all([ 
        AccountSaga(),
        DataSaga(),
        StockSaga(),
        MinigameSaga(),
        ZreationSaga(),
        DapperSaga(),
        StooEventSaga(),
    ])
}
