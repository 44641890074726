export const STOCK_LIST = "STOCK_LIST"
export const STOCK_LIST_ASYNC = "STOCK_LIST_ASYNC"
export const STOCK_CREATE = "STOCK_CREATE"
export const STOCK_CREATE_ASYNC = "STOCK_CREATE_ASYNC"
export const STOCK_ACTIVE = "STOCK_ACTIVE"
export const STOCK_ACTIVE_ASYNC = "STOCK_ACTIVE_ASYNC"
export const STOCK_SEND_MAIL = "STOCK_SEND_MAIL"
export const STOCK_SEND_MAIL_ASYNC = "STOCK_SEND_MAIL_ASYNC"
export const STOCK_GET_CONFIRM = "STOCK_GET_CONFIRM"
export const STOCK_GET_CONFIRM_ASYNC = "STOCK_GET_CONFIRM_ASYNC"
export const STOCK_UPGRADE = "STOCK_UPGRADE"
export const STOCK_UPGRADE_ASYNC = "STOCK_UPGRADE_ASYNC"
export const STOCK_FORGOT_PASSWORD = "STOCK_FORGOT_PASSWORD"
export const STOCK_FORGOT_PASSWORD_ASYNC = "STOCK_FORGOT_PASSWORD_ASYNC"
export const STOCK_DELETE = "STOCK_DELETE"
export const STOCK_DELETE_ASYNC = "STOCK_DELETE_ASYNC"
export const STOCK_CREATE_VIEW_ONLY = "STOCK_CREATE_VIEW_ONLY"
export const STOCK_CREATE_VIEW_ONLY_ASYNC = "STOCK_CREATE_VIEW_ONLY_ASYNC"