import * as TYPE from './types'

const initialState = {
  data_minigame: [],
  message: "",
  status: 500,
}

function MinigameReducer(state = initialState, action) {
  switch (action.type) {
    // Minigame
    case TYPE.MINIGAME_LIST_ASYNC:
      return {
        ...state,
        data_minigame: action.payload.data,
        message: "",
        status: 500
      }
    case TYPE.MINIGAME_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.MINIGAME_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }
    case TYPE.MINIGAME_ACTIVE_ASYNC:
    case TYPE.MINIGAME_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      }

    default:
      return state
  }
}

export default MinigameReducer