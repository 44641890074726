import React, { useState, useEffect } from 'react'
import { Drawer, Dropdown, Icon } from 'rsuite'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const ContentComponent = (props) => {
  const [state, setState] = useState({
    active: 'data',
    is_open_drawer: false,
  })

  const toggle = () => {
    setState({
      ...state,
      is_open_drawer: !state.is_open_drawer
    })
  }

  useEffect(
    () => {
      // /stock
      let slicePath = window.location.pathname.split('/');
      let path = slicePath[2];

      setState({
        ...state,
        active: path || "data"
      });
    }, [])

  const onSelect = (activeKey) => {
    setState({
      ...state,
      active: activeKey
    });
  }

  return (
    <section className="hamburger-icon">
      <header className="container">
        <span className="logo" onClick={toggle} />
        <Drawer
          placement={"right"}
          show={state.is_open_drawer}
          onHide={toggle}
          full
          style={{
            maxWidth: 320
          }}
        >
          <Drawer.Header>
          </Drawer.Header>
          <Drawer.Body>
            <ul className="hamburger-icon--menu">
              <li onClick={e => onSelect('data')}>
                <a
                  className={classnames({
                    active: state.active === 'data'
                  })}
                  href="/app/data">{props.i18n.messages['nav.data']}</a>
              </li>
              <li onClick={e => onSelect('stock')}>
                <a
                  className={classnames({
                    active: state.active === 'stock'
                  })}
                  href="/app/stock">{props.i18n.messages['nav.stock']}</a>
              </li>
              <Dropdown
                title={props.i18n.messages['nav.addon']}
                onClick={e => onSelect('zreation')}
                className={classnames("cus-rs-dropdown-menu", {
                  active: state.active === 'zreation'
                })}>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/event">
                    Quản lý sự kiện
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/zreation">
                    Zreation
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/dapper">
                    Dapper
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/minigame">
                    {props.i18n.messages['nav.minigame']}
                  </Link>
                </Dropdown.Item>
              </Dropdown>
              {/* <Dropdown
                title={props.i18n.messages['nav.minigame']}
                onClick={e => onSelect('minigame')}
                className={classnames("cus-rs-dropdown-menu", {
                  active: state.active === 'minigame'
                })}>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/video">
                    Video
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item componentClass="span">
                  <Link to="/app/minigame">
                    Mini game
                  </Link>
                </Dropdown.Item>
              </Dropdown> */}
              <li onClick={props.onClickLogout}>
                <a href="#">{props.i18n.messages['logout']}</a>
              </li>
            </ul>
          </Drawer.Body>
        </Drawer>
      </header>
    </section>
  )
}

export default ContentComponent;