import * as TYPE from './types'

const dispatchStockListAsync = (data) => ({
  type: TYPE.STOCK_LIST,
  data,
});
const dispatchStockCreateAsync = (data) => ({
  type: TYPE.STOCK_CREATE,
  data,
});
const dispatchStockActiveAsync = (data) => ({
  type: TYPE.STOCK_ACTIVE,
  data,
});
const dispatchStockSendMailAsync = (data) => ({
  type: TYPE.STOCK_SEND_MAIL,
  data,
});
const dispatchStockGetConfirmAsync = (data) => ({
  type: TYPE.STOCK_GET_CONFIRM,
  data,
});
const dispatchStockUpgradeAsync = (data) => ({
  type: TYPE.STOCK_UPGRADE,
  data,
});
const dispatchStockForgotPasswordAsync = (data) => ({
  type: TYPE.STOCK_FORGOT_PASSWORD,
  data,
});
const dispatchStockDeleteAsync = (data) => ({
  type: TYPE.STOCK_DELETE,
  data,
});
const dispatchStockCreateViewOnlyAsync = (data) => ({
  type: TYPE.STOCK_CREATE_VIEW_ONLY,
  data,
});

export default {
  dispatchStockListAsync,
  dispatchStockCreateAsync,
  dispatchStockActiveAsync,
  dispatchStockSendMailAsync,
  dispatchStockGetConfirmAsync,
  dispatchStockUpgradeAsync,
  dispatchStockForgotPasswordAsync,
  dispatchStockDeleteAsync,
  dispatchStockCreateViewOnlyAsync,
};

export const ListAsync = (payload) => {
  return {
    type: TYPE.STOCK_LIST_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.STOCK_CREATE_ASYNC,
    payload
  }
}
export const ActiveAsync = (payload) => {
  return {
    type: TYPE.STOCK_ACTIVE_ASYNC,
    payload
  }
}
export const SendMailAsync = (payload) => {
  return {
    type: TYPE.STOCK_SEND_MAIL_ASYNC,
    payload
  }
}
export const GetConfirmAsync = (payload) => {
  return {
    type: TYPE.STOCK_GET_CONFIRM_ASYNC,
    payload
  }
}
export const UpgradeAsync = (payload) => {
  return {
    type: TYPE.STOCK_UPGRADE_ASYNC,
    payload
  }
}
export const ForgotPasswordAsync = (payload) => {
  return {
    type: TYPE.STOCK_FORGOT_PASSWORD_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.STOCK_DELETE_ASYNC,
    payload
  }
}
export const CreateViewOnlyAsync = (payload) => {
  return {
    type: TYPE.STOCK_CREATE_VIEW_ONLY_ASYNC,
    payload
  }
}