const mapStateToProps = state => {
  return {
    i18n: state.I18nReducers,
    accounts: state.AccountReducers,
    data: state.DataReducers,
    stock: state.StockReducers,
    minigame: state.MinigameReducers,
    zreation: state.ZreationReducers,
    dapper: state.DapperReducers,
    stoo_event: state.StooEventReducers,
  };
};
export default mapStateToProps;