import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import {
  Dropdown
} from 'rsuite'
import { Link } from 'react-router-dom'

const ContentComponent = (props) => {
  const [state, setState] = useState({
    active: 'data',
  })

  useEffect(
    () => {
      // /stock
      let slicePath = window.location.pathname.split('/');
      let path = slicePath[2];

      setState({
        ...state,
        active: path || "data"
      });
    }, [])

  const onSelect = (activeKey) => {
    setState({
      ...state,
      active: activeKey
    });
  }

  return (
    <ul className="toolbar__navigation--menu">
      <li onClick={e => onSelect('data')}>
        <a
          className={classnames({
            active: state.active === 'data'
          })}
          href="/app/data">{props.i18n.messages['nav.data']}</a>
      </li>
      <li onClick={e => onSelect('stock')}>
        <a
          className={classnames({
            active: state.active === 'stock'
          })}
          href="/app/stock">{props.i18n.messages['nav.stock']}</a>
      </li>
      <Dropdown
        title={props.i18n.messages['nav.addon']}
        onClick={e => onSelect('zreation')}
        className={classnames({
          active: state.active === 'zreation'
        })}>
        <Dropdown.Item componentClass="span">
          <Link to="/app/event">
            Quản lý sự kiện
          </Link>
        </Dropdown.Item>
        <Dropdown.Item componentClass="span">
          <Link to="/app/zreation">
            Zreation
          </Link>
        </Dropdown.Item>
        <Dropdown.Item componentClass="span">
          <Link to="/app/dapper">
            Dapper
          </Link>
        </Dropdown.Item>
        <Dropdown.Item componentClass="span">
          <Link to="/app/minigame">
            {props.i18n.messages['nav.minigame']}
          </Link>
        </Dropdown.Item>
      </Dropdown>
      {/* <Dropdown
        title={props.i18n.messages['nav.minigame']}
        onClick={e => onSelect('minigame')}
        className={classnames({
          active: state.active === 'minigame'
        })}>
        <Dropdown.Item componentClass="span">
          <Link to="/app/video">
            Video
          </Link>
        </Dropdown.Item>
        <Dropdown.Item componentClass="span">
          <Link to="/app/minigame">
            Mini game
          </Link>
        </Dropdown.Item>
      </Dropdown> */}
      <li onClick={props.onClickLogout}>
        <a href="#">{props.i18n.messages['logout']}</a>
      </li>
    </ul>
  )
}

export default ContentComponent